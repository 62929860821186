exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buero-tsx": () => import("./../../../src/pages/buero.tsx" /* webpackChunkName: "component---src-pages-buero-tsx" */),
  "component---src-pages-gewerbe-tsx": () => import("./../../../src/pages/gewerbe.tsx" /* webpackChunkName: "component---src-pages-gewerbe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-leitbild-tsx": () => import("./../../../src/pages/leitbild.tsx" /* webpackChunkName: "component---src-pages-leitbild-tsx" */),
  "component---src-pages-planungen-tsx": () => import("./../../../src/pages/planungen.tsx" /* webpackChunkName: "component---src-pages-planungen-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-wohnen-tsx": () => import("./../../../src/pages/wohnen.tsx" /* webpackChunkName: "component---src-pages-wohnen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

